import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/varieties-lager.module.scss";
// import star from "../svg/home-star.svg"
import scroll from "../svg/home-scroll.svg";
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";
import plus from "../svg/mobile-varieties-plus.svg";

const Varieties = () => {
    const [currentPackage, setCurrentPackage] = useState(1);
    const [details, setDetails] = useState("disabled");

    return (
        <div className="section">
            <div
                className={`${styles.varietiesContainer} ${
                    details === "noire" && styles.varietiesContainerNoire
                }`}
            >
                <div className={styles.varietiesLeftHalf}>
                    {currentPackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/can-blanche.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "regular" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentPackage === 2 && (
                        <StaticImage
                            src="../images/Varieties/975blanche.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Long Neck"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "regular" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {details !== "noire" && (
                        <button
                            className={styles.varietiesButton}
                            onClick={() => {
                              window.dataLayer.push({
                                event: "GAEvent",
                                event_category: "Product",
                                event_action: `Content`,
                                event_label: "Ver Más",
                                interaction: true,
                                component_name: "boton_ver_mas",
                                element_text: "Ver Más",
                            });
                                details !== "regular"
                                    ? setDetails("regular")
                                    : setDetails("disabled");


                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}
                    <img
                        src={plus}
                        alt="Ver más"
                        className={`${styles.varietiesPlus} ${
                            details === "regular" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => setDetails("regular")}
                    />
                    <img
                        src={arrowLeft}
                        alt="Volver"
                        className={`${styles.varietiesBack} ${
                            details !== "regular" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => setDetails("disabled")}
                    />

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                    ${
                        details === "noire" &&
                        styles.varietiesMiniaturesContainerDifferentFocus
                    }
                    `}
                    >
                        <img
                            src={arrowLeft}
                            alt="Arrow Left"
                            className={styles.varietiesArrowLeft}
                            onClick={() =>
                                currentPackage > 1 &&
                                setCurrentPackage(currentPackage - 1)
                            }
                        />
                        {currentPackage < 3 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentPackage(1)}
                            >
                                <StaticImage
                                    src="../images/Varieties/can-blanche.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentPackage === 1 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        269 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Lata
                                    </div>
                                </button>
                            </div>
                        )}
                        {currentPackage < 4 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentPackage(2)}
                            >
                                <StaticImage
                                    src="../images/Varieties/975blanche.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentPackage === 2 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        975 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Botella
                                    </div>
                                </button>
                            </div>
                        )}

                        <img
                            src={arrowRight}
                            alt="Arrow Right"
                            className={styles.varietiesArrowRight}
                            onClick={() =>
                                currentPackage < 2 &&
                                setCurrentPackage(currentPackage + 1)
                            }
                        />
                    </div>

                    {details === "regular" && (
                        <div className={styles.varietiesContent}>
                            <div className={styles.varietiesOverline}>
                                Stella Blanche
                            </div>
                            <div className={styles.varietiesTitle}>
                                PREMIUM WHITE LAGER
                            </div>
                            <div className={styles.varietiesBoldText}>
                                Es una variedad con notas frescas y cítricas de un amargor ligero y con un gran aroma por su lúpulo Mandarina Bavaria. Amigable a cualquier paladar cervecero.
                                Con un 5% de alcohol, es fácil de tomar y marida perfectamente con comidas livianas y frescas como ensaladas, pescados y postres cítricos.
                            </div>
                            <div className={styles.varietiesText}>
                                Para disfrutarla mejor, servila en Chalice, con dos dedos de espuma y perfumala con un twist de piel de naranja.
                            </div>
                            {/* <button className={styles.varietiesBuyButton}>
                <a
                  href="https://www.craftsociety.com.ar/pages/stella-artois/?utm_source=stella-artois&utm_medium=cpc&utm_campaing=stella-artois_heartbeats&utm_content=ad"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comprar
                </a>
              </button> */}
                        </div>
                    )}
                </div>
                <a href="/#herencia" title="Herencia">
                    <img
                        className={styles.varietiesScroll}
                        src={scroll}
                        alt="Scroll"
                    />
                </a>
                <div
                    className={`${
                        details === "regular" &&
                        styles.varietiesOnFocusBackgroundRegular
                    }
        ${styles.varietiesLeftHalfBackground}`}
                >
                    <StaticImage
                        src="../images/Varieties/blanche-background.png"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Varieties;
