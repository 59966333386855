import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { navigate } from "gatsby";
import { IsMobileDevice } from "../utils/functions";
import Menu from "../components/menu.jsx";
import Varieties from "../components/varieties.jsx";
import VarietiesLager from "../components/varieties-lager.jsx";
import VarietiesNoire from "../components/varieties-noire.jsx";
import AboutUsHeritage from "../components/about-us-heritage.jsx";
import AboutUsIngredients from "../components/about-us-ingredients.jsx";
import Footer from "../components/footer.jsx";
import Loader from "../components/loader.jsx";
import BocadoModal from "../components/bocado-modal";
import BocadoCart from "../components/bocado-cart";
import Seo from "../components/seo";
import styles from "../styles/sobre-nosotros.module.scss";
import star from "../svg/bocado-star.svg";
import {
    showLoaderAtom,
    showFooterAtom,
    showStarAtom,
    currentHeritageSlideAtom,
    isMobileAtom,
    showNewsletterModalAtom,
    showCartDrawerAtom,
} from "../recoil/atoms.js";
import Cookies from "js-cookie";
// @ts-ignore
import ReactFullpage from "@fullpage/react-fullpage";
import { window, document } from "browser-monads";
import SecondVarieties from "../components/second-varieties";
import VarietiesBlanche from "../components/varieties-blanche";
import VarietiesZero from "../components/varieties-zero";

const AboutUs = ({ data }) => {
    const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);
    const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);

    const [showNewsletterModal, setShowNewsletterModal] = useRecoilState(
        showNewsletterModalAtom
    );
    const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false);
    const [showCartDrawer, setShowCartDrawer] = useRecoilState(
        showCartDrawerAtom
    );

    const [showStar, setShowStar] = useRecoilState(showStarAtom);
    const setShowFooter = useSetRecoilState(showFooterAtom);
    const [currentHeritageSlide, setCurrentHeritageSlide] = useRecoilState(
        currentHeritageSlideAtom
    );

    useEffect(() => {
        setIsMobile(IsMobileDevice());
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight / 100}px`
        );
    }, []);

    useEffect(() => {
        let local = localStorage.getItem("age");
        let session = sessionStorage.getItem("age");
        if (!local && !session) navigate("/agegate");
        else {
            setShowLoader(false);
        }
    }, []);

    useEffect(() => {
        window?.dataLayer.push({
            brand: "Stella Artois",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Sobre Stella",
            pageType: "Basic page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: `${window.location.href}`,
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        });
    }, []);

    const onLeave = (origin, destination, direction) => {
        currentHeritageSlide !== 1 &&
            setTimeout(function () {
                setCurrentHeritageSlide(1);
            }, 1000);
        if (destination.anchor === "variedades") {
            setTimeout(function () {
                setShowStar(true);
            }, 200);
            setShowFooter(false);
        } else if (destination.anchor === "variedades2") {
            setTimeout(function () {
                setShowStar(true);
            }, 200);
            setShowFooter(false);
        }else if (destination.anchor === "herencia") {
            setTimeout(function () {
                setShowStar(true);
            }, 200);
            setShowFooter(false);
        } else if (destination.anchor === "ingredientes") {
            setShowStar(false);
            setShowFooter(false);
        } else if (destination.anchor === "footer") {
            setShowStar(false);
            setShowFooter(true);
        }
    };
    return (
        <>
            <Seo title="Stella Artois" />
            {showLoader && <Loader />}
            {showNewsletterModal && (
                <BocadoModal
                    type="newsletter"
                    isMobile={isMobile}
                    setShowModal={setShowNewsletterModal}
                    title="BIENVENIDO A BOCADO CLUB"
                    subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
                />
            )}
            {showFooterSuccessModal && (
                <BocadoModal
                    isMobile={isMobile}
                    setShowModal={setShowFooterSuccessModal}
                    onlySuccess={true}
                />
            )}
            <BocadoCart
                showCartDrawer={showCartDrawer}
                setShowCartDrawer={setShowCartDrawer}
            />
            <Menu
                isMobile={isMobile}
                setShowNewsletterModal={setShowNewsletterModal}
                setShowCartDrawer={setShowCartDrawer}
            />
            <div className={styles.homeContainer}>
                <img
                    className={`${styles.homeStar} ${
                        !showStar && styles.homeStarDisabled
                    }`}
                    src={star}
                    alt="Stella Artois Star"
                />
            </div>
            <ReactFullpage
                licenseKey={"OPEN-SOURCE-GPLV3-LICENSE"}
                scrollingSpeed={1000}
                anchors={
                    isMobile
                        ? [
                              "herencia",
                              "ingredientes",
                              "variedades",
                              "noire",
                              "blanche",
                              "zero",
                              "footer",
                          ]
                        : ["herencia", "ingredientes", "variedades", "variedades2", "footer"]
                }
                onLeave={onLeave}
                render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div className={styles.homeContainer}>
                                <AboutUsHeritage />
                                <AboutUsIngredients />
                                {isMobile ? (
                                    <>
                                        <VarietiesLager />
                                        <VarietiesNoire />
                                    </>
                                ) : (
                                    <>
                                        <Varieties />
                                    </>
                                )}
                                {isMobile ? (
                                    <>
                                        <VarietiesBlanche />
                                        <VarietiesZero />
                                    </>
                                ) : (
                                    <>
                                        <SecondVarieties />
                                    </>
                                )}
                                {!isMobile && (
                                    <div
                                        className={
                                            "section" +
                                            " " +
                                            styles.emptySection
                                        }
                                    ></div>
                                )}
                                {isMobile && (
                                    <Footer
                                        link={"/sobre-stella#herencia"}
                                        aboutUs={true}
                                        isMobile={isMobile}
                                        setShowModal={setShowFooterSuccessModal}
                                    />
                                )}
                            </div>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
            {!isMobile && (
                <Footer
                    link={"/sobre-stella#herencia"}
                    aboutUs={true}
                    isMobile={isMobile}
                    setShowModal={setShowFooterSuccessModal}
                />
            )}
        </>
    );
};

export default AboutUs;
