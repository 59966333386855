import React, {useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import styles from "../styles/varieties.module.scss";
// import scroll from "../svg/bocado-scroll.svg"
// import scrollWhite from "../svg/varieties-scroll-white.svg"
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";

const SecondVarieties = () => {
    const [currentBlanchePackage, setCurrentBlanchePackage] = useState(1);
    const [details, setDetails] = useState("disabled");

    return (
        <div className="section" style={{zIndex: "20"}}>
            <div
                className={`${styles.varietiesContainer} ${
                    details === "noire" && styles.varietiesContainerNoire
                }`}
            >
                <div className={styles.varietiesLeftHalf}>
                    {currentBlanchePackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/can-blanche.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentBlanchePackage === 2 && (
                        <StaticImage
                            src="../images/Varieties/975blanche.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Long Neck"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}


                    {details !== "noire" && (
                        <button
                            className={styles.varietiesButton}
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Product",
                                    event_action: `Content`,
                                    event_label: "Ver Más",
                                    interaction: true,
                                    component_name: "boton_ver_mas",
                                    element_text: "Ver Más",
                                });
                                details !== "regular"
                                    ? setDetails("regular")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                    ${
                            details === "noire" &&
                            styles.varietiesMiniaturesContainerDifferentFocus
                        }
                    `}
                    >
                        <img
                            src={arrowLeft}
                            alt="Arrow Left"
                            className={styles.varietiesArrowLeft}
                            onClick={() =>
                                currentBlanchePackage > 1 &&
                                setCurrentBlanchePackage(currentBlanchePackage - 1)
                            }
                        />
                        {(
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentBlanchePackage(1)}
                            >
                                <StaticImage
                                    src="../images/Varieties/can-blanche.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentBlanchePackage === 1 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        269 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Lata
                                    </div>
                                </button>
                            </div>
                        )}
                        {currentBlanchePackage < 5 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentBlanchePackage(2)}
                            >
                                <StaticImage
                                    src="../images/Varieties/975blanche.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentBlanchePackage === 2 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        975 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Botella
                                    </div>
                                </button>
                            </div>
                        )}
                        <img
                            src={arrowRight}
                            alt="Arrow Right"
                            className={styles.varietiesArrowRight}
                            onClick={() =>
                                currentBlanchePackage < 2 &&
                                setCurrentBlanchePackage(currentBlanchePackage + 1)
                            }
                        />
                    </div>

                    {details === "noire" && (
                        <div className={styles.varietiesNoireContent}>
                            <div className={styles.varietiesNoireOverline}>
                                Stella 0%
                            </div>
                            <div className={styles.varietiesNoireTitle}>
                                PREMIUM LAGER SIN ALCOHOL
                            </div>
                            <div className={styles.varietiesBoldNoireText}>
                                Sin alcohol, con todo lo demás. La variedad Pale Lager es una cerveza rubia, sin
                                alcohol, de sabor amargo y fresco, superior al de otras lagers, que la convierten en el
                                acompañamiento ideal de una gran variedad de platos salados.
                            </div>
                            <div className={styles.varietiesNoireText}>
                                Disponible en botella de 330 ml.
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.varietiesRightHalf}>
                    {(
                        <StaticImage
                            src="../images/Varieties/330zero.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}

                    {details !== "regular" && (
                        <button
                            className={`${styles.varietiesButton} ${
                                details === "noire" &&
                                styles.varietiesButtonNoire
                            }`}
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Product",
                                    event_action: `Content`,
                                    event_label: "Ver Más",
                                    interaction: true,
                                    component_name: "boton_ver_mas",
                                    element_text: "Ver Más",
                                });

                                details !== "noire"
                                    ? setDetails("noire")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                            ${
                            details === "regular" &&
                            styles.varietiesMiniaturesContainerDifferentFocus
                        }
                            `}
                    >

                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                        >
                            <StaticImage
                                src="../images/Varieties/330zero.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    styles.varietiesSingleMiniatureButtonActive
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    330 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Botella
                                </div>
                            </button>
                        </div>


                    </div>

                    {details === "regular" && (
                        <div className={styles.varietiesContent}>
                            <div className={styles.varietiesOverline}>
                                Stella Blanche
                            </div>
                            <div className={styles.varietiesTitle}>
                                PREMIUM WHITE LAGER
                            </div>
                            <div className={styles.varietiesBoldText}>
                                Es una variedad con notas frescas y cítricas de un amargor ligero y con un gran aroma
                                por su lúpulo Mandarina Bavaria. Amigable a cualquier paladar cervecero.
                                Con un 5% de alcohol, es fácil de tomar y marida perfectamente con comidas livianas y
                                frescas como ensaladas, pescados y postres cítricos.
                            </div>
                            <div className={styles.varietiesText}>
                                Para disfrutarla mejor, servila en Chalice, con dos dedos de espuma y perfumala con un
                                twist de piel de naranja.
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={`${
                        details === "noire" &&
                        styles.varietiesOnFocusBackgroundNoire
                    }
            ${details !== "noire" && styles.varietiesRightHalfBackground}
             ${details === "regular" && styles.varietiesDifferentFocusBackground}
`}
                >
                    <StaticImage
                        src="../images/Varieties/zero-background.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                        objectPosition={"50% 35%"}

                    />
                </div>
                <div
                    className={`${
                        details === "regular" &&
                        styles.varietiesOnFocusBackgroundRegular
                    }
                ${details !== "regular" && styles.varietiesBlancheBackground}
                ${
                        details === "noire" &&
                        styles.varietiesDifferentFocusBackground
                    }`}
                >
                    <StaticImage
                        src="../images/Varieties/blanche-background.png"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                        objectPosition={"50% 35%"}

                    />
                </div>
            </div>
        </div>
    );
};

export default SecondVarieties;
