import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/varieties.module.scss";
// import scroll from "../svg/bocado-scroll.svg"
// import scrollWhite from "../svg/varieties-scroll-white.svg"
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";

const Varieties = () => {
    const [currentLagerPackage, setCurrentLagerPackage] = useState(1);
    const [currentNoirePackage, setCurrentNoirePackage] = useState(1);
    const [details, setDetails] = useState("disabled");

    return (
        <div className="section" style={{ zIndex: "20" }}>
            <div
                className={`${styles.varietiesContainer} ${
                    details === "noire" && styles.varietiesContainerNoire
                }`}
            >
                <div className={styles.varietiesLeftHalf}>
                    {currentLagerPackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/269can.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentLagerPackage === 2 && (
                        <StaticImage
                            src="../images/Varieties/can.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Long Neck"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentLagerPackage === 3 && (
                        <StaticImage
                            src="../images/Varieties/long-neck.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Botella"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentLagerPackage === 4 && (
                        <StaticImage
                            src="../images/Varieties/710bottle.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Botella"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentLagerPackage === 5 && (
                        <StaticImage
                            src="../images/Varieties/bottle.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Botella"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {details !== "noire" && (
                        <button
                            className={styles.varietiesButton}
                            onClick={() => {
                              window.dataLayer.push({
                                event: "GAEvent",
                                event_category: "Product",
                                event_action: `Content`,
                                event_label: "Ver Más",
                                interaction: true,
                                component_name: "boton_ver_mas",
                                element_text: "Ver Más",
                            });
                                details !== "regular"
                                    ? setDetails("regular")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                    ${
                        details === "noire" &&
                        styles.varietiesMiniaturesContainerDifferentFocus
                    }
                    `}
                    >
                        <img
                            src={arrowLeft}
                            alt="Arrow Left"
                            className={styles.varietiesArrowLeft}
                            onClick={() =>
                                currentLagerPackage > 1 &&
                                setCurrentLagerPackage(currentLagerPackage - 1)
                            }
                        />
                        {currentLagerPackage < 3 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentLagerPackage(1)}
                            >
                                <StaticImage
                                    src="../images/Varieties/269can.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentLagerPackage === 1 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        269 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Lata
                                    </div>
                                </button>
                            </div>
                        )}
                        {currentLagerPackage < 4 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentLagerPackage(2)}
                            >
                                <StaticImage
                                    src="../images/Varieties/can.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentLagerPackage === 2 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        473 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Lata
                                    </div>
                                </button>
                            </div>
                        )}
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentLagerPackage(3)}
                        >
                            <StaticImage
                                src="../images/Varieties/long-neck.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentLagerPackage === 3 &&
                                    styles.varietiesSingleMiniatureButtonActive
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    330 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Long Neck
                                </div>
                            </button>
                        </div>
                        {currentLagerPackage > 2 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentLagerPackage(4)}
                            >
                                <StaticImage
                                    src="../images/Varieties/710bottle.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentLagerPackage === 4 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        710 ml
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Botella
                                    </div>
                                </button>
                            </div>
                        )}
                        {currentLagerPackage > 3 && (
                            <div
                                className={
                                    styles.varietiesSingleMiniatureContainer
                                }
                                onClick={() => setCurrentLagerPackage(5)}
                            >
                                <StaticImage
                                    src="../images/Varieties/bottle.png"
                                    height={500}
                                    quality={70}
                                    formats={["AUTO"]}
                                    placeholder={"blurred"}
                                    alt="Lata"
                                    objectFit="contain"
                                    className={
                                        styles.varietiesSingleMiniaturePicture
                                    }
                                />
                                <button
                                    className={`${
                                        styles.varietiesSingleMiniatureButton
                                    } ${
                                        currentLagerPackage === 5 &&
                                        styles.varietiesSingleMiniatureButtonActive
                                    }`}
                                >
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonTitle
                                        }
                                    >
                                        1 l
                                    </div>
                                    <div
                                        className={
                                            styles.varietiesSingleMiniatureButtonDescription
                                        }
                                    >
                                        Botella
                                    </div>
                                </button>
                            </div>
                        )}
                        <img
                            src={arrowRight}
                            alt="Arrow Right"
                            className={styles.varietiesArrowRight}
                            onClick={() =>
                                currentLagerPackage < 5 &&
                                setCurrentLagerPackage(currentLagerPackage + 1)
                            }
                        />
                    </div>

                    {details === "noire" && (
                        <div className={styles.varietiesNoireContent}>
                            <div className={styles.varietiesNoireOverline}>
                                Nueva
                            </div>
                            <div className={styles.varietiesNoireTitle}>
                                Noire
                            </div>
                            <div className={styles.varietiesBoldNoireText}>
                                Cerveza negra premium, de espuma cremosa, color
                                negro intenso y aroma a maltas tostadas.
                            </div>
                            <div className={styles.varietiesNoireText}>
                                Equilibrada, de cuerpo justo y amargor
                                balanceado. Nace de la combinación de una
                                selección de finas maltas tostadas y el
                                prestigioso lúpulo Saaz, exclusivo de Stella
                                Artois.
                            </div>
                            {/* <a
                href="https://www.craftsociety.com.ar/pages/stella-artois/?utm_source=stella-artois&utm_medium=cpc&utm_campaing=stella-artois_heartbeats&utm_content=ad"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.varietiesNoireBuyButton}>
                  Comprar
                </button>
              </a> */}
                        </div>
                    )}
                </div>
                <div className={styles.varietiesRightHalf}>
                    {currentNoirePackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/269noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture}  ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentNoirePackage === 2 && (
                        <StaticImage
                            src="../images/Varieties/473-noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Long Neck"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentNoirePackage === 3 && (
                        <StaticImage
                            src="../images/Varieties/bottle-noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Botella"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {details !== "regular" && (
                        <button
                            className={`${styles.varietiesButton} ${
                                details === "noire" &&
                                styles.varietiesButtonNoire
                            }`}
                            onClick={() => {
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Product",
                                    event_action: `Content`,
                                    event_label: "Ver Más",
                                    interaction: true,
                                    component_name: "boton_ver_mas",
                                    element_text: "Ver Más",
                                });

                                details !== "noire"
                                    ? setDetails("noire")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                            ${
                                details === "regular" &&
                                styles.varietiesMiniaturesContainerDifferentFocus
                            }
                            `}
                    >
                        <img
                            src={arrowLeft}
                            alt="Arrow Left"
                            className={styles.varietiesArrowLeft}
                            onClick={() =>
                                currentNoirePackage > 1 &&
                                setCurrentNoirePackage(currentNoirePackage - 1)
                            }
                        />
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentNoirePackage(1)}
                        >
                            <StaticImage
                                src="../images/Varieties/269noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentNoirePackage === 1 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    269 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Lata
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentNoirePackage(2)}
                        >
                            <StaticImage
                                src="../images/Varieties/473-noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentNoirePackage === 2 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    473 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Lata
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentNoirePackage(3)}
                        >
                            <StaticImage
                                src="../images/Varieties/bottle-noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentNoirePackage === 3 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    1 l
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Botella
                                </div>
                            </button>
                        </div>
                        <img
                            src={arrowRight}
                            alt="Arrow Right"
                            className={styles.varietiesArrowRight}
                            onClick={() =>
                                currentNoirePackage < 3 &&
                                setCurrentNoirePackage(currentNoirePackage + 1)
                            }
                        />
                    </div>

                    {details === "regular" && (
                        <div className={styles.varietiesContent}>
                            <div className={styles.varietiesOverline}>
                                La original
                            </div>
                            <div className={styles.varietiesTitle}>
                                Pale Lager
                            </div>
                            <div className={styles.varietiesBoldText}>
                                La variedad Pale Lager es una cerveza rubia de
                                sabor amargo y fresco, superior al de otras
                                lagers, que la convierten en el acompañamiento
                                ideal de una gran variedad de platos salados.
                            </div>
                            <div className={styles.varietiesText}>
                                Disponible en botella de 330 ml y en lata de 473
                                ml, tamaño ideal para aquellos que quieren un
                                poco más que el clásico porrón.
                            </div>
                            {/* <a
                href="https://www.craftsociety.com.ar/pages/stella-artois/?utm_source=stella-artois&utm_medium=cpc&utm_campaing=stella-artois_heartbeats&utm_content=ad"
                target="_blank"
                rel="noreferrer"
              >
                <button className={styles.varietiesBuyButton}>Comprar</button>
              </a> */}
                        </div>
                    )}
                </div>
                {/* <img
        className={`${styles.varietiesStar} ${
          details !== "disabled" && styles.varietiesDisabled
        }`}
        src={star}
        alt="Stella Artois Star"
      /> */}
                {/* <a href="/#herencia" title="Herencia">
          <img
            className={`${styles.varietiesScroll} ${
              details === "noire" && styles.varietiesScrollDesktopHide
            }`}
            src={scroll}
            alt="Scroll"
          />
        </a> */}
                {/* <a href="/#herencia" title="Herencia">
          <img
            className={`${
              details === "noire"
                ? styles.varietiesScrollWhite
                : styles.varietiesScrollDesktopHide
            }`}
            src={scrollWhite}
            alt="Scroll"
          />
        </a> */}
                <div
                    className={`${
                        details === "noire" &&
                        styles.varietiesOnFocusBackgroundNoire
                    }
        ${details !== "noire" && styles.varietiesRightHalfBackground}
        ${details === "regular" && styles.varietiesDifferentFocusBackground}
`}
                >
                    <StaticImage
                        src="../images/Varieties/noir-background.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                    />
                </div>
                <div
                    className={`${
                        details === "regular" &&
                        styles.varietiesOnFocusBackgroundRegular
                    }
                ${details !== "regular" && styles.varietiesLeftHalfBackground}
                ${
                    details === "noire" &&
                    styles.varietiesDifferentFocusBackground
                }`}
                >
                    <StaticImage
                        src="../images/Varieties/left-background.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Varieties;
