import React, { useState } from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/about-us-ingredients.module.scss";
import star from "../svg/bocado-star.svg";
import scroll from "../svg/bocado-scroll.svg";
import plus from "../svg/ingredients-plus.svg";
import close from "../svg/ingredients-close.svg";

const AboutUsIngredients = ({ siteTitle }) => {
    const [currentIngredient, setCurrentIngredient] = useState("all");

    return (
        <div className="section">
            <div className={styles.ingredientsContainer}>
                <img
                    className={styles.ingredientsStar}
                    src={star}
                    alt="Stella Artois Star"
                />
                <div className={styles.incredientsYeastContainer}>
                    <div
                        className={`${styles.incredientsPlusAndTitle} ${
                            currentIngredient !== "yeast" &&
                            currentIngredient !== "all" &&
                            styles.ingredientsDisabled
                        }`}
                    >
                        <div
                            className={
                                styles.ingredientsSingleIngredientTitleLeft
                            }
                        >
                            Levadura
                        </div>
                        <img
                            className={`${styles.ingredientsPlusLeft} ${
                                currentIngredient === "all"
                                    ? styles.ingredientsPlusLeftAnimation
                                    : styles.ingredientsPlusLeftFixed
                            }`}
                            src={currentIngredient === "all" ? plus : close}
                            alt="Read More"
                            onClick={() => {
                                currentIngredient === "all" &&
                                    setCurrentIngredient("yeast");
                                currentIngredient === "yeast" &&
                                    setCurrentIngredient("all");

                           
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Content",
                                        event_action: `Boton`,
                                        event_label: "Levadura",
                                        interaction: true,
                                        component_name: "boton_levadura",
                                        element_text: "Levadura",
                                    });
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`${styles.ingredientsDescriptionYeast} ${
                        currentIngredient !== "yeast" &&
                        styles.ingredientsDisabled
                    }`}
                >
                    La levadura es definitivamente un componente clave en
                    nuestra cerveza, ya que utilizamos una levadura pura y
                    única, que solo se usa para producir Stella Artois. Se
                    encuentra guardada en un ambiente seguro, en el que usamos
                    tecnología avanzada para conservar su calidad y mantenerla
                    en perfectas condiciones. Nuestra levadura nos ayuda a
                    lograr el nivel de claridad de la cerveza, y los sabores y
                    aromas únicos que posee.
                </div>
                <div className={styles.incredientsBarleyContainer}>
                    <div
                        className={`${styles.incredientsPlusAndTitle} ${
                            currentIngredient !== "barley" &&
                            currentIngredient !== "all" &&
                            styles.ingredientsDisabled
                        }`}
                    >
                        <div
                            className={
                                styles.ingredientsSingleIngredientTitleLeft
                            }
                        >
                            Malta de cebada
                        </div>
                        <img
                            className={`${styles.ingredientsPlusLeft} ${
                                currentIngredient === "all"
                                    ? styles.ingredientsPlusLeftAnimation
                                    : styles.ingredientsPlusLeftFixed
                            }`}
                            src={currentIngredient === "all" ? plus : close}
                            alt="Read More"
                            onClick={() => {
                                currentIngredient === "all" &&
                                    setCurrentIngredient("barley");
                                currentIngredient === "barley" &&
                                    setCurrentIngredient("all");

                             
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Content",
                                        event_action: `Boton`,
                                        event_label: "Malta de cebada",
                                        interaction: true,
                                        component_name: "boton_malta",
                                        element_text: "Malta de cebada",
                                    });
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`${styles.ingredientsDescriptionBarley} ${
                        currentIngredient !== "barley" &&
                        styles.ingredientsDisabled
                    }`}
                >
                    La malta de cebada usada para hacer Stella Artois se obtiene
                    de plantaciones locales y se produce en la maltería de Tres
                    Arroyos. Esta malta de cebada de alta calidad, es
                    responsable del llamativo color dorado de nuestra cerveza,
                    de su cuerpo y del leve sabor a cereales y malta que posee.
                </div>
                <div className={styles.incredientsHopsContainer}>
                    <div
                        className={`${styles.incredientsPlusAndTitle} ${
                            currentIngredient !== "hops" &&
                            currentIngredient !== "all" &&
                            styles.ingredientsDisabled
                        }`}
                    >
                        <img
                            className={`${styles.ingredientsPlusRight} ${
                                currentIngredient === "all" &&
                                styles.ingredientsPlusBounce
                            }`}
                            src={currentIngredient === "all" ? plus : close}
                            alt="Read More"
                            onClick={() => {
                                currentIngredient === "all" &&
                                    setCurrentIngredient("hops");
                                currentIngredient === "hops" &&
                                    setCurrentIngredient("all");

                            
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Content",
                                        event_action: `Boton`,
                                        event_label: "Lúpulo",
                                        interaction: true,
                                        component_name: "boton_lupulo",
                                        element_text: "Lúpulo",
                                    });
                            }}
                        />
                        <div
                            className={
                                styles.ingredientsSingleIngredientTitleRight
                            }
                        >
                            Lúpulo
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles.ingredientsDescriptionHops} ${
                        currentIngredient !== "hops" &&
                        styles.ingredientsDisabled
                    }`}
                >
                    Desde 1926, Stella Artois usa los famosos lúpulos Saaz
                    provenientes de República Checa. El Saaz pertenece a un
                    grupo de lúpulos nobles, en el que también se encuentran los
                    Spalt, Tettnang y Hallertau Mittelfrüh. Estos lúpulos son
                    reconocidos a nivel mundial y reciben su nombre de las
                    regiones de las que proceden. Se caracterizan por ser
                    variedades que no provienen del cruce de otras y por sus
                    inconfundibles aromas. Este lúpulo noble crea en nuestra
                    cerveza ligeras notas florales, perfumadas y resinosas.
                </div>
                <div className={styles.incredientsWaterContainer}>
                    <div
                        className={`${styles.incredientsPlusAndTitle} ${
                            currentIngredient !== "water" &&
                            currentIngredient !== "all" &&
                            styles.ingredientsDisabled
                        }`}
                    >
                        <img
                            className={`${styles.ingredientsPlusRight} ${
                                currentIngredient === "all" &&
                                styles.ingredientsPlusBounce
                            }`}
                            src={currentIngredient === "all" ? plus : close}
                            alt="Read More"
                            onClick={() => {
                                currentIngredient === "all" &&
                                    setCurrentIngredient("water");
                                currentIngredient === "water" &&
                                    setCurrentIngredient("all");

                            
                                    window.dataLayer.push({
                                        event: "GAEvent",
                                        event_category: "Content",
                                        event_action: `Boton`,
                                        event_label: "Agua",
                                        interaction: true,
                                        component_name: "boton_agua",
                                        element_text: "Agua",
                                    });
                            }}
                        />
                        <div
                            className={
                                styles.ingredientsSingleIngredientTitleRight
                            }
                        >
                            Agua
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles.ingredientsDescriptionWater} ${
                        currentIngredient !== "water" &&
                        styles.ingredientsDisabled
                    }`}
                >
                    El agua constituye el 92% del volumen de la cerveza. Por eso
                    usamos agua de la mejor calidad, absolutamente pura, que
                    permite que los aromas y el gusto de la levadura, el lúpulo
                    y la malta se luzcan. Aparte, la calidad del agua usada para
                    hacer Stella Artois es una de las principales razones de por
                    qué nuestra cerveza es tan cristalina.
                </div>
                <a href="/sobre-stella#variedades" title="Experiencias">
                    <img
                        className={styles.ingredientsScroll}
                        src={scroll}
                        alt="Scroll"
                    />
                </a>
                <div className={styles.ingredientsBackgroundGradient}></div>
                <StaticImage
                    src="../images/Ingredients/background-all.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Background"
                    className={`${styles.ingredientsBackground} ${
                        currentIngredient === "all"
                            ? styles.ingredientsActiveBackground
                            : styles.ingredientsDisabledBackground
                    }`}
                />
                <StaticImage
                    src="../images/Ingredients/background-yeast.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Background"
                    className={`${styles.ingredientsBackground} ${
                        currentIngredient === "yeast"
                            ? styles.ingredientsActiveBackground
                            : styles.ingredientsDisabledBackground
                    }`}
                />
                <StaticImage
                    src="../images/Ingredients/background-barley.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Background"
                    className={`${styles.ingredientsBackground} ${
                        currentIngredient === "barley"
                            ? styles.ingredientsActiveBackground
                            : styles.ingredientsDisabledBackground
                    }`}
                />
                <StaticImage
                    src="../images/Ingredients/background-hops.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Background"
                    className={`${styles.ingredientsBackground} ${
                        currentIngredient === "hops"
                            ? styles.ingredientsActiveBackground
                            : styles.ingredientsDisabledBackground
                    }`}
                />
                <StaticImage
                    src="../images/Ingredients/background-water.jpg"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    alt="Background"
                    className={`${styles.ingredientsBackground} ${
                        currentIngredient === "water"
                            ? styles.ingredientsActiveBackground
                            : styles.ingredientsDisabledBackground
                    }`}
                />
                <StaticImage
                    src="../images/Ingredients/chalice.png"
                    height={1080}
                    quality={70}
                    formats={["AUTO"]}
                    placeholder={"blurred"}
                    objectFit={"contain"}
                    alt="Background"
                    className={styles.ingredientsChalice}
                />
                <div className={styles.ingredientsTitle}>
                    Ingredientes de calidad, para un sabor distinto
                </div>
            </div>
        </div>
    );
};

AboutUsIngredients.propTypes = {
    siteTitle: PropTypes.string,
};

AboutUsIngredients.defaultProps = {
    siteTitle: ``,
};

export default AboutUsIngredients;
