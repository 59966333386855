import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/varieties-noire.module.scss";
// import star from "../svg/home-star.svg"
import scrollWhite from "../svg/varieties-scroll-white.svg";
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";
import plus from "../svg/mobile-varieties-plus.svg";

const Varieties = () => {
    const [currentPackage, setCurrentPackage] = useState(1);
    const [details, setDetails] = useState("disabled");

    return (
        <div className="section">
            <div
                className={`${styles.varietiesContainer} ${
                    details === "noire" && styles.varietiesContainerNoire
                }`}
            >
                <div className={styles.varietiesRightHalf}>
                    {currentPackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/330zero.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {details !== "regular" && (
                        <button
                            className={`${styles.varietiesButton} ${
                                details === "noire" &&
                                styles.varietiesButtonNoire
                            }`}
                            onClick={() => {

                                details !== "noire"
                                    ? setDetails("noire")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}
                    <img
                        src={plus}
                        alt="Ver más"
                        className={`${styles.varietiesPlus} ${
                            details === "noire" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => {


                            setDetails("noire");
                        }}
                    />
                    <img
                        src={arrowLeft}
                        alt="Volver"
                        className={`${styles.varietiesBack} ${
                            details !== "noire" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => setDetails("disabled")}
                    />

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                            ${
                                details === "regular" &&
                                styles.varietiesMiniaturesContainerDifferentFocus
                            }
                            `}
                    >

                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentPackage(1)}
                        >
                            <StaticImage
                                src="../images/Varieties/330zero.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentPackage === 1 &&
                                    styles.varietiesSingleMiniatureButtonActive
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    330 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Botella
                                </div>
                            </button>
                        </div>
                    </div>

                    {details === "noire" && (
                        <div className={styles.varietiesNoireContent}>
                            <div className={styles.varietiesNoireOverline}>
                                Stella 0%
                            </div>
                            <div className={styles.varietiesNoireTitle}>
                                PREMIUM LAGER SIN ALCOHOL
                            </div>
                            <div className={styles.varietiesBoldNoireText}>
                                Sin alcohol, con todo lo demás. La variedad Pale Lager es una cerveza rubia, sin alcohol, de sabor amargo y fresco, superior al de otras lagers, que la convierten en el acompañamiento ideal de una gran variedad de platos salados.
                            </div>
                            <div className={styles.varietiesNoireText}>
                                Disponible en botella de 330 ml.
                            </div>
                            {/* <button className={styles.varietiesNoireBuyButton}>
                <a
                  href="https://www.craftsociety.com.ar/pages/stella-artois/?utm_source=stella-artois&utm_medium=cpc&utm_campaing=stella-artois_heartbeats&utm_content=ad"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comprar
                </a>
              </button> */}
                        </div>
                    )}
                </div>
                {/* <img
        className={`${styles.varietiesStar} ${
          details !== "disabled" && styles.varietiesDisabled
        }`}
        src={star}
        alt="Stella Artois Star"
      /> */}
                <a href="/#herencia" title="Herencia">
                    <img
                        className={`${styles.varietiesScrollWhite} ${
                            details === "regular" &&
                            styles.varietiesScrollDesktopHide
                        }`}
                        src={scrollWhite}
                        alt="Scroll"
                    />
                </a>
                <div
                    className={`${
                        details === "noire" &&
                        styles.varietiesOnFocusBackgroundNoire
                    }
        ${styles.varietiesRightHalfBackground}`}
                >
                    <StaticImage
                        src="../images/Varieties/zero-background.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Varieties;
