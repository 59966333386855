import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useSwipeable } from "react-swipeable";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/about-us-heritage.module.scss";
import scroll from "../svg/bocado-scroll.svg";
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";
import vintageLogo from "../svg/heritage-vintage-logo.svg";
import logo from "../svg/menu-logo.svg";
import { currentHeritageSlideAtom, showStarAtom } from "../recoil/atoms.js";

const AboutUsHeritage = () => {
    const [showScroll, setShowScroll] = useState(true);
    const [currentHeritageSlide, setCurrentHeritageSlide] = useRecoilState(
        currentHeritageSlideAtom
    );
    const setShowStar = useSetRecoilState(showStarAtom);

    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () => previousSlide(),
    });

    const previousSlide = () => {
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Carousel Sobre Stella",
            event_action: `Slide Nº${currentHeritageSlide}`,
            event_label: "Ver más",
            interaction: true,
            component_name: "boton_ver_mas_carousel",
            element_text: "Ver Más",
        });
        if (currentHeritageSlide === 2) {
            setShowStar(true);
            setCurrentHeritageSlide(1);
        }
        if (currentHeritageSlide === 3) {
            setShowScroll(true);
            setCurrentHeritageSlide(2);
            setTimeout(function () {
                setCurrentHeritageSlide(1);
                setShowStar(true);
            }, 1000);
        }
    };

    const nextSlide = () => {
        window.dataLayer.push({
            event: "GAEvent",
            event_category: "Carousel Sobre Stella",
            event_action: `Slide Nº${currentHeritageSlide}`,
            event_label: "Ver más",
            interaction: true,
            component_name: "boton_ver_mas_carousel",
            element_text: "Ver Más",
        });
        if (currentHeritageSlide < 3) {
            setShowStar(false);
            setCurrentHeritageSlide(currentHeritageSlide + 1);
        }
    };

    useEffect(() => {
        currentHeritageSlide === 1 &&
            showScroll === false &&
            setShowScroll(true);
        currentHeritageSlide === 2 &&
            showScroll === false &&
            setShowScroll(true);
        currentHeritageSlide === 3 &&
            showScroll === true &&
            setShowScroll(false);
    }, [currentHeritageSlide]);

    return (
        <div className="section">
            <div className={styles.heritageContainer} {...handlers}>
                {currentHeritageSlide > 1 && (
                    <img
                        src={arrowLeft}
                        alt="Arrow Left"
                        className={styles.heritageArrowLeft}
                        onClick={() => previousSlide()}
                    />
                )}
                {currentHeritageSlide < 3 && (
                    <img
                        src={arrowRight}
                        alt="Arrow Right"
                        className={styles.heritageArrowRight}
                        onClick={() => nextSlide()}
                    />
                )}
                {showScroll && (
                    <a href="/sobre-stella#ingredientes" title="Ingredientes">
                        <img
                            className={styles.heritageScroll}
                            src={scroll}
                            alt="Scroll"
                        />
                    </a>
                )}
                <div
                    className={`${styles.heritageSlide01Container} ${
                        currentHeritageSlide === 1
                            ? styles.heritageActiveSlide
                            : styles.heritageDisabledSlideLeft
                    }`}
                >
                    {/* <img
          className={styles.heritageStar}
          src={star}
          alt="Stella Artois Star"
        /> */}
                    <StaticImage
                        src="../images/Heritage/01edited.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage01}
                    />
                    <StaticImage
                        src="../images/Heritage/02.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage02}
                    />
                    <div className={styles.heritageSlide01Content}>
                        {/* <div className={styles.heritageSlide01Overline}>
              Nuestros orígenes
            </div> */}
                        <div className={styles.heritageSlide01Title}>
                            600 años de herencia belga
                        </div>
                        <div className={styles.heritageSlide01Text}>
                            En Stella Artois estamos muy orgullosos de nuestras
                            raices belgas. Nuestra tradición cervecera comienza
                            hace 600 años, en la ciudad de Lovaina, cuando en
                            1366 se funda la cervecería Den Horen.
                        </div>
                        <button
                            className={styles.heritageButton}
                            onClick={() => {
                                setCurrentHeritageSlide(2);
                                setShowStar(false);
                                window.dataLayer.push({
                                    event: "GAEvent",
                                    event_category: "Content",
                                    event_action: `Boton`,
                                    event_label: "Ver Más",
                                    interaction: true,
                                    component_name: "boton_ver_mas",
                                    element_text: "Ver Más",
                                });
                            }}
                        >
                            Ver más
                        </button>
                    </div>
                </div>
                <div
                    className={`${styles.heritageSlide02Container} ${
                        currentHeritageSlide === 1 &&
                        styles.heritageDisabledSlideRight
                    }
          ${currentHeritageSlide === 2 && styles.heritageActiveSlide}
            ${currentHeritageSlide === 3 && styles.heritageDisabledSlideLeft}`}
                >
                    <div className={styles.heritageSlide02Content}>
                        <div className={styles.heritageSlide02Text}>
                            Stella Artois nació como una edición especial para
                            la época de navidad.
                            <br className={styles.heritageBR} />
                            <br className={styles.heritageBR} />
                            El nombre "Stella", que significa "estrella" en
                            latín, hace referencia a esa fecha, y es la razón
                            del dibujo de la estrella que se encuentra plasmada
                            en todas nuestra botellas. El nombre "Artois" rinde
                            homenaje al dueño y maestro cervecero de Den Hores,
                            Sebastian Artois.
                        </div>
                        <div className={styles.heritageSlide02StrongText}>
                            Esta edición especial tuvo tanto éxito que se empezó
                            a vender durante todo el año, y al día de hoy,
                            continúa honrando su legado.
                        </div>
                    </div>
                    <StaticImage
                        src="../images/Heritage/03.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage03}
                    />
                    <StaticImage
                        src="../images/Heritage/04.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage04}
                    />
                    <img
                        src={vintageLogo}
                        alt="Stella Artois Logo"
                        className={styles.heritageVintageLogo}
                    />
                    {/* <img
            src={vintageLogo}
            alt="Stella Artois Logo"
            className={styles.heritageVintageLogoWatermark}
          /> */}
                </div>
                <div
                    className={`${styles.heritageSlide03Container} ${
                        currentHeritageSlide === 3
                            ? styles.heritageActiveSlide
                            : styles.heritageDisabledSlideRight
                    }`}
                >
                    <StaticImage
                        src="../images/Heritage/05.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage05}
                    />
                    <StaticImage
                        src="../images/Heritage/07.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage07}
                    />
                    <StaticImage
                        src="../images/Heritage/06.jpg"
                        height={540}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Stella Artois Heritage"
                        className={styles.heritageImage06}
                    />
                    <div className={styles.heritageSlide03Text}>
                        Cada botella de Stella Artois lleva consigo una gran
                        historia, acompañada de un delicioso sabor que nos hace
                        cada vez más orgullosos.
                    </div>
                    <img
                        className={styles.heritageLogo}
                        src={logo}
                        alt="Stella Artois"
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutUsHeritage;
