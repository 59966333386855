import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styles from "../styles/varieties-noire.module.scss";
// import star from "../svg/home-star.svg"
import scrollWhite from "../svg/varieties-scroll-white.svg";
import arrowLeft from "../svg/arrow-left.svg";
import arrowRight from "../svg/arrow-right.svg";
import plus from "../svg/mobile-varieties-plus.svg";

const Varieties = () => {
    const [currentPackage, setCurrentPackage] = useState(1);
    const [details, setDetails] = useState("disabled");

    return (
        <div className="section">
            <div
                className={`${styles.varietiesContainer} ${
                    details === "noire" && styles.varietiesContainerNoire
                }`}
            >
                <div className={styles.varietiesRightHalf}>
                    {currentPackage === 1 && (
                        <StaticImage
                            src="../images/Varieties/269noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Lata"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentPackage === 2 && (
                        <StaticImage
                            src="../images/Varieties/473-noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Long Neck"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {currentPackage === 3 && (
                        <StaticImage
                            src="../images/Varieties/bottle-noire.png"
                            height={1080}
                            quality={70}
                            formats={["AUTO"]}
                            placeholder={"blurred"}
                            alt="Botella"
                            objectFit="contain"
                            className={`${styles.varietiesMainPicture} ${
                                details === "noire" &&
                                styles.varietiesMainPictureDetailsView
                            } ${
                                details === "regular" &&
                                styles.varietiesMainPictureDifferentDetailsView
                            }`}
                        />
                    )}
                    {details !== "regular" && (
                        <button
                            className={`${styles.varietiesButton} ${
                                details === "noire" &&
                                styles.varietiesButtonNoire
                            }`}
                            onClick={() => {

                                details !== "noire"
                                    ? setDetails("noire")
                                    : setDetails("disabled");
                            }}
                        >
                            {details === "disabled" ? "Ver más" : "Volver"}
                        </button>
                    )}
                    <img
                        src={plus}
                        alt="Ver más"
                        className={`${styles.varietiesPlus} ${
                            details === "noire" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => {


                            setDetails("noire");
                        }}
                    />
                    <img
                        src={arrowLeft}
                        alt="Volver"
                        className={`${styles.varietiesBack} ${
                            details !== "noire" &&
                            styles.varietiesPlusOrBackDisabled
                        }`}
                        onClick={() => setDetails("disabled")}
                    />

                    <div
                        className={`${styles.varietiesMiniaturesContainer}
                            ${
                                details === "regular" &&
                                styles.varietiesMiniaturesContainerDifferentFocus
                            }
                            `}
                    >
                        <img
                            src={arrowLeft}
                            alt="Arrow Left"
                            className={styles.varietiesArrowLeft}
                            onClick={() =>
                                currentPackage > 1 &&
                                setCurrentPackage(currentPackage - 1)
                            }
                        />
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentPackage(1)}
                        >
                            <StaticImage
                                src="../images/Varieties/269noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentPackage === 1 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    269 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Lata
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentPackage(2)}
                        >
                            <StaticImage
                                src="../images/Varieties/473-noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentPackage === 2 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    473 ml
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Lata
                                </div>
                            </button>
                        </div>
                        <div
                            className={styles.varietiesSingleMiniatureContainer}
                            onClick={() => setCurrentPackage(3)}
                        >
                            <StaticImage
                                src="../images/Varieties/bottle-noire.png"
                                height={500}
                                quality={70}
                                formats={["AUTO"]}
                                placeholder={"blurred"}
                                alt="Lata"
                                objectFit="contain"
                                className={
                                    styles.varietiesSingleMiniaturePicture
                                }
                            />
                            <button
                                className={`${
                                    styles.varietiesSingleMiniatureButton
                                } ${
                                    currentPackage === 3 &&
                                    styles.varietiesSingleMiniatureButtonNoire
                                }`}
                            >
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonTitle
                                    }
                                >
                                    1 l
                                </div>
                                <div
                                    className={
                                        styles.varietiesSingleMiniatureButtonDescription
                                    }
                                >
                                    Botella
                                </div>
                            </button>
                        </div>
                        <img
                            src={arrowRight}
                            alt="Arrow Right"
                            className={styles.varietiesArrowRight}
                            onClick={() =>
                                currentPackage < 3 &&
                                setCurrentPackage(currentPackage + 1)
                            }
                        />
                    </div>

                    {details === "noire" && (
                        <div className={styles.varietiesNoireContent}>
                            <div className={styles.varietiesNoireOverline}>
                                Nueva
                            </div>
                            <div className={styles.varietiesNoireTitle}>
                                Noire
                            </div>
                            <div className={styles.varietiesBoldNoireText}>
                                Cerveza negra premium, de espuma cremosa, color
                                negro intenso y aroma a maltas tostadas.
                            </div>
                            <div className={styles.varietiesNoireText}>
                                Equilibrada, de cuerpo justo y amargor
                                balanceado. Nace de la combinación de una
                                selección de finas maltas tostadas y el
                                prestigioso lúpulo Saaz, exclusivo de Stella
                                Artois.
                            </div>
                            {/* <button className={styles.varietiesNoireBuyButton}>
                <a
                  href="https://www.craftsociety.com.ar/pages/stella-artois/?utm_source=stella-artois&utm_medium=cpc&utm_campaing=stella-artois_heartbeats&utm_content=ad"
                  target="_blank"
                  rel="noreferrer"
                >
                  Comprar
                </a>
              </button> */}
                        </div>
                    )}
                </div>
                {/* <img
        className={`${styles.varietiesStar} ${
          details !== "disabled" && styles.varietiesDisabled
        }`}
        src={star}
        alt="Stella Artois Star"
      /> */}
                <a href="/#herencia" title="Herencia">
                    <img
                        className={`${styles.varietiesScrollWhite} ${
                            details === "regular" &&
                            styles.varietiesScrollDesktopHide
                        }`}
                        src={scrollWhite}
                        alt="Scroll"
                    />
                </a>
                <div
                    className={`${
                        details === "noire" &&
                        styles.varietiesOnFocusBackgroundNoire
                    }
        ${styles.varietiesRightHalfBackground}`}
                >
                    <StaticImage
                        src="../images/Varieties/noir-background.jpg"
                        height={1080}
                        quality={70}
                        formats={["AUTO"]}
                        alt="Background"
                        className={styles.varietiesBackgroundImage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Varieties;
